import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../../ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkTenantReportAiClient } from "../../sdk/SdkTenantReportAiClient"
import { ChatComponent, ChatResponseOperation } from "../ChatComponent/ChatControl"
import { FluentProvider } from "@fluentui/react-components"
import { useAppShellContext } from "../../ds/components/AppShell"
import { ISdkAiResponseMessageAction } from "../../sdk/models/ISdkAiResponseMessage"

export interface IReportAddAiDialogProps extends IAppWindowBaseProps {
    tenantId: string
    reportCollectionType: "serviceProviderCollection" | undefined  
    
    dismissDialogEx?: (action: string, reportId: string) => void 
}

export const ReportAddAiDialog = (props: IReportAddAiDialogProps) => {

    const auth = useAuthInContext(props.tenantId)
    const appShellContext = useAppShellContext()        
                
    const onDismissDialog = () => {                
        if (props.dismissDialog)
            props.dismissDialog()
    }    

    const onAction = (actionId: string, action: ISdkAiResponseMessageAction) => {

        // extract the report id from the target path 
        const reportId = action.TargetPath.split('/').pop() as string

        // open the report panel
        if (props.dismissDialogEx) 
            props.dismissDialogEx(actionId, reportId)        
    }

    const onUserRequest = async (prompt: string, assistantResponseHandler: (response: ChatResponseOperation) => void) => {
                
        if (prompt.startsWith('echo:')) {

            // give a status notification
            assistantResponseHandler({ message: 'Thanks a lot for your request, looking for a solution please stay tuned...', actions: []})

            // delay via wait operations
            await new Promise(resolve => setTimeout(resolve, 3000))

            // still working
            assistantResponseHandler({ message: 'Still working...', actions: []})

            // delay via wait operations
            await new Promise(resolve => setTimeout(resolve, 3000))

            // final response
            assistantResponseHandler({ message: 'ECHO: ' + prompt, actions: []})

        } else {
            // give a status notification
            assistantResponseHandler({ message: 'Thanks a lot for your request, looking for a solution please stay tuned...', actions: []})

            // build the sdk client
            const aiClient = new SdkTenantReportAiClient(auth.currentToken as string)
            await aiClient.AskAi('createreport', props.tenantId, prompt, (responseMessage) => {

                // send status updates 
                if (responseMessage.AssistantMessage.startsWith('debug')) { return; }

                // generate the actions                 
                const actions: { label: string, icon: string, onClick: () => void }[] = []                
                responseMessage.Actions.forEach(action => {

                    switch(action.Key) {
                        case 'openReport':                            
                            actions.push({ label: 'Open Report', icon: 'faFileChartColumn', onClick: () => onAction('openReport', action) })
                            actions.push({ label: 'Edit Report', icon: 'faPen', onClick: () => onAction('editReport', action) })
                            break;
                        default:
                            break;
                    }                    
                })

                // update the chat with the response
                assistantResponseHandler({ message: responseMessage.AssistantMessage, actions: actions})
                                    
            })        
        }
    }

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Just explain your need and we will do the rest'}            
            onDismiss={onDismissDialog}            
            onSubmit={() => Promise.resolve()}
            noDissmissButton={true}
            type={PanelType.large} 
            progessMessage={'Creating the repport...'} 
            submitLabel={'Close'} 
            dissmissLabel={'Close'} 
            isValid={true}>

            <FluentProvider theme={appShellContext.themeV9} style={{height: '100%'}}>                            
                <ChatComponent height='200px' onUserRequest={onUserRequest}/>                                          
            </FluentProvider>
        </PanelWithDefaultFooter>)
}