import { IconButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import './AppBar.scss';
import { useBranding } from './BrandingProvider';
import { useState } from 'react';

export interface IAppBarItem {
    key: string
    iconName: string
    title: string
    path: string    
}

export interface IAppBarProps {
    items: IAppBarItem[]
    onEvent: (event: string) => void
    isPreparing?: boolean    
}

export const AppBar = (props: IAppBarProps) => {

    const navigate = useNavigate()
    const branding = useBranding()
    
    const [activeItem, setActiveItem] = useState<IAppBarItem | undefined>(undefined)

    const onClickHandler = (item: IAppBarItem) => {

        if (item.path.startsWith('event:')) {
            props.onEvent(item.path.replace('event:', ''))            
        } else {
            setActiveItem(item)
            navigate(item.path as string)        
        }
    }

    const navBarStyles = branding.company?.colors.primaryHover && branding.company?.colors.primaryHover !== '' ? {        
        rootHovered: { color: branding.company?.colors.primaryHover }        
    } : undefined

    const activityIndicatorColor = branding.company?.colors.primaryHover && branding.company?.colors.primaryHover !== '' ? branding.company?.colors.primaryHover : 'inherited'

    return (
        <div className="mv-app-bar">
            <style
                dangerouslySetInnerHTML={{
                __html: `
                    div.mv-app-bar-item.mv-app-bar-item-selected button::before {
                        background-color: ` + activityIndicatorColor + ` !important;                                    
                }`
                }}
            ></style>

            { props.isPreparing ? 
                <div>                    
                    <Spinner size={SpinnerSize.small} style={{marginTop: '17px'}} />
                </div> : 
                props.items.map((item) => {
                    return (
                        <div className={'mv-app-bar-item' + (activeItem && activeItem.key === item.key ? ' mv-app-bar-item-selected' : '')} key={item.key}>                                                        
                            <IconButton iconProps={{ iconName: item.iconName }} title={item.title} onClick={() => { onClickHandler(item) }} styles={navBarStyles} />                            
                        </div>
                    )
                })
            }           
        </div>)
}

