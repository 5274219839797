import { Stack } from "@fluentui/react"
import { HeaderButton } from "./lib/components/Generic/HeaderButton"
import { useSubscriptionContext } from "./lib/authentication/AuthenticationSubscriptionContext";
import { UnreadBadgeControlled } from "@changeloghq/changeloghq-sdk-browser";
import { useBranding } from "./lib/ds/components/BrandingProvider";

export interface IAppNewFeaturesIconProps {
    onClick?: () => void
    url: string
}

export const AppNewFeaturesIcon = (props: IAppNewFeaturesIconProps) => {
    
    const subContext = useSubscriptionContext()        
    const branding = useBranding()    
        
    if (subContext.subscriptions.find(s => s.id === 'subscription.cloudbilling.serviceprovider')) {
        return (            
            <Stack.Item>
                <HeaderButton iconProps={{ iconName: 'faGift' }} onClick={props.onClick}>
                    <UnreadBadgeControlled url={props.url} onClick={props.onClick} hideIfZero={true} color={branding.company?.colors.primary} backgroundColor={branding.company?.colors.primaryFont} left={16} top={-1} />
                </HeaderButton>                                       
            </Stack.Item>                        
        )
    } else {
        return (<></>)
    }
}