import { Persona, PersonaSize, Stack } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom"
import { AppNavigationManager } from "./AppNavigationManager";
import { useProfileContext } from "./lib/authentication/AuthenticationProfileContext";
import { useEffectAsync } from "./lib/components/Helpers/EffectAsyncHook";
import { AppBar, IAppBarItem } from "./lib/ds/components/AppBar";
import { AppContent } from "./lib/ds/components/AppContent";
import { AppPersonaCallout } from "./lib/ds/components/AppPersonalCallout";
import { NavigationBar } from "./lib/ds/components/NavigationBar";
import { useBranding } from "./lib/ds/components/BrandingProvider";
import { ISdkTenantEx, loadActiveTenantDetailsFromProfileContext } from "./lib/authentication/AuthenticationProfileHelper";
import { createEvent } from "react-event-hook";
import AuthenticationSubscriptionProvider from "./lib/authentication/AuthenticationSubscriptionProvider";
import { ActivityLogPanel } from "./lib/components/ActivityLog/ActivityLogPanel";
import { AppNewFeaturesIcon } from "./AppNewFeaturesIcon";
import { ChangelogPanel } from "./lib/components/ChangelogPanel";

const defaultTitle = "Cloud Billing"

export interface AppDynamicNavigationService {
        
    removeAllItems(): void

    addNavigationItem(key: string, iconName: string, title: string, path: string): void

    deleteNavigationItem(key: string): void

    addActionItem(key: string, iconName: string, title: string, action: string): void

    setTitle(title?: string): void

    setPreparing(isPreparing: boolean): void

    useEventListener: (listener: (event: string) => void) => void
}


export const AppDynamicNavigate = () => {
    
    const branding = useBranding()

    const profileContext = useProfileContext();
    const { tenantId } = useParams();
    
    const authInContext = useAuthInContext(tenantId as string)    
        
    const location = useLocation();
        
    const [isAvatarCalloutVisible, { toggle: toggleisAvatarCalloutVisible }] = useBoolean(false);  
    const avatarId = useId('avatar-persona');
       
    const [navigationItems, setNavigationItems] = useState<IAppBarItem[]>([])

    const [title, setTitle] = useState<string>('')
    const [activeTenant, setActiveTenant] = useState<ISdkTenantEx | undefined>(undefined)
    
    const { useNavigationActionListener, emitNavigationAction } = createEvent("navigationAction")<string>();

    const [isPreparing, setIsPreparing] = useState<boolean>(false);
    const [isAuditLogVisible, setIsAuditLogVisible] = useState(false);    
    
    const [isChangelogVisible, setIsChangelogVisible] = useState(false);

    const updateServiceUri = 'https://rn.mevodo.com'

    useEffectAsync(async () => {

        if (!tenantId) { return }

        // try to find the active tenant
        const activeTenant = await loadActiveTenantDetailsFromProfileContext(tenantId, authInContext, profileContext)
        setActiveTenant(activeTenant)

        // handle the title
        if (activeTenant && activeTenant.isAdminOnBehalfOf) {
            setTitle(activeTenant.name);          
        } else {
            setTitle(branding.company?.name || defaultTitle);
        }
                
    }, [tenantId])

    if (!tenantId) {
        return (<Navigate to={'/welcome/select' } />)
    }
    
    if (location.pathname.endsWith(tenantId)) {    

        // try to find the tenant
        const selectedTenant = profileContext.profile.tenants.find(t => t.id === tenantId);
        if (!selectedTenant) { 
            return (<Navigate to={'/welcome/select' } />)
        }

        // decide what we open the end user portal or the tenant as self         
        if (selectedTenant && selectedTenant.managed) {
            return (<Navigate to={'/tenants/' + tenantId + '/portal' } />)
        } else {            
            return (<Navigate to={'/tenants/' + tenantId + '/svp/customers' } />)
        }

    } else {    
                
        const appDynamicNavigationService: AppDynamicNavigationService = {

            removeAllItems: () => {
                setNavigationItems([])
            },

            addNavigationItem: (key: string, iconName: string, title: string, path: string) => {   
                
                const navigationItem = {
                    key: key,
                    iconName: iconName,
                    title: title,
                    path: path
                }

                setNavigationItems(prevItems => [...prevItems, navigationItem])                        
            },

            deleteNavigationItem: (key: string) => {
                setNavigationItems(prevItems => prevItems.filter(item => item.key !== key))                        
            },
            
            addActionItem: (key: string, iconName: string, title: string, action: string) => {   
                
                const navigationItem = {
                    key: key,
                    iconName: iconName,
                    title: title,
                    path: 'event:' + action
                }

                setNavigationItems(prevItems => [...prevItems, navigationItem])                        
            },

            setTitle: (title?: string): void => {
                
                if (!title) {
                    setTitle(branding.company?.name || defaultTitle)
                } else {
                    setTitle(title)
                }
            },

            useEventListener: (listener: (event: string) => void) => {
                useNavigationActionListener(listener)
            },

            setPreparing: (isPreparing: boolean) => {
                setIsPreparing(isPreparing)
            }
        }   
        
        const onEvent = (event: string) => {
            emitNavigationAction(event)            
        }
            
        return (
            <div>             
                <AppDynamicNavigateContext.Provider value={appDynamicNavigationService}>      
                    <AuthenticationSubscriptionProvider>
                        <NavigationBar title={title} icon={branding.company?.logos['small']}>                            
                            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                                <AppNewFeaturesIcon url={updateServiceUri} onClick={() => {setIsChangelogVisible(true)}} />                                
                                <Stack.Item>
                                    <Persona 
                                        imageUrl={profileContext.profile.avatarUri} 
                                        size={PersonaSize.size32}  
                                        showSecondaryText={false}                               
                                        className='mv-nav-bar-persona'
                                        id={avatarId}
                                        onClick={toggleisAvatarCalloutVisible}
                                        onDoubleClick={toggleisAvatarCalloutVisible}
                                        />
                                </Stack.Item>
                            </Stack>                    
                        </NavigationBar>
                        <AppBar items={navigationItems} onEvent={onEvent} isPreparing={isPreparing}/>
                        <AppContent>
                            <AppNavigationManager activeTenant={activeTenant} auth={authInContext}>
                                <Outlet />  
                            </AppNavigationManager>
                        </AppContent>                                     
                        <AppPersonaCallout isVisible={isAvatarCalloutVisible} targetId={avatarId} onDismiss={() => { toggleisAvatarCalloutVisible() }} onShowAuditLog={(visible) => { setIsAuditLogVisible(visible) }}/>   
                        <ActivityLogPanel tenantId={tenantId as string} isVisible={isAuditLogVisible} objectDisplayName={profileContext.profile.firstName + ' ' + profileContext.profile.lastName} objectType={'self'} objectId={profileContext.profile.profileId} dismissDialog={() => { setIsAuditLogVisible(false) }}/>                        
                        <ChangelogPanel isVisible={isChangelogVisible} dismissDialog={() => { setIsChangelogVisible(false) }} url={updateServiceUri} />                            
                    </AuthenticationSubscriptionProvider>         
                </AppDynamicNavigateContext.Provider>
            </div>
        )
    }
}

export const AppDynamicNavigateContext = React.createContext<AppDynamicNavigationService>(undefined!);

export const useNavigationService = () => {
    return React.useContext(AppDynamicNavigateContext);
};