import { CommandBar, ICommandBarItemProps } from "@fluentui/react"
import { ICommandDescription } from "../../commands/ICommandDescription"
import { useBranding } from "../../ds/components/BrandingProvider"
import { ISdkEnvironmentBranding } from "../../sdk/models/ISdkEnvironmentBranding"

export enum CommandBarItems {
    default,
    farItems,
    farItemsIconOnly
}

export interface ICommandBarService {
    Command: (key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string) => ICommandBarService
    CommandByDescription: (commandDescription: ICommandDescription, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string) => ICommandBarService
    FarCommand: (key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string ) => ICommandBarService
    SubCommand: (key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean ) => ICommandBarService

    HasItems(): boolean,
    GetCommandBarItems: (itemType: CommandBarItems) => ICommandBarItemProps[]    

    GetCommandDescriptions: () => ICommandDescription[]
}

export interface ICommandBarProps {
    service: ICommandBarService
}

export const ControlledCommandBar = (props: ICommandBarProps) => {
    return (
        <div className="mv-command-bar-wrapper">
             <CommandBar items={props.service.GetCommandBarItems(CommandBarItems.default)} farItems={props.service.GetCommandBarItems(CommandBarItems.farItems)} />
        </div>
    )
}

class CommandBarService implements ICommandBarService {
    private _items: ICommandBarItemProps[] = []
    private _farItems: ICommandBarItemProps[] = []

    private _buttonHoveredStyle: any | undefined
    private _subItemHoveredStyle: any | undefined

    constructor(private _branding: ISdkEnvironmentBranding | undefined) 
    {
        this._buttonHoveredStyle = this._branding?.colors.primaryHover && this._branding?.colors.primaryHover !== '' ? {
            color: _branding?.colors.primaryHover,
            selectors: {
                '.ms-Button-icon': {
                  color: this._branding?.colors.primaryHover
                }                        
            }
        } : undefined

        this._subItemHoveredStyle = this._branding?.colors.primaryHover && this._branding?.colors.primaryHover !== '' ? {            
            root: {
                selectors: {
                    '.ms-ContextualMenu-link:hover, .ms-ContextualMenu-link:hover .ms-ContextualMenu-icon': {
                        color: this._branding?.colors.primaryHover
                    }                                            
                }
            }
        } : undefined
    }

    commandFunction(target: ICommandBarItemProps[], key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string): ICommandBarService {
                
        // find the item with the same key and delete 
        const existingIndex = target.findIndex(i => i.key === key)
        if (existingIndex >= 0) { target.splice(existingIndex, 1) }

        // just in case the item is hidden do nothing
        if (hidden) { return this }

        
        var element = {
            key: key,
            text: name,
            cacheKey: key, 
            iconProps: { iconName: iconName },
            onClick: onClick,
            disabled: disabled,
            buttonStyles: {
                root: {
                    backgroundColor: selected ? 'rgb(243, 242, 241)' : 'transparent',
                },
                rootHovered: this._buttonHoveredStyle
            }              
        }

        if (after && after === '_first') {
            target.splice(0, 0, element)            
        } else if (after) {             
            // add item after index 
            const afterIndex = target.findIndex(i => i.key === after)
            if (afterIndex >= 0) { target.splice(afterIndex + 1, 0, element) }
        } else {
            // push the new item 
            target.push(element)
        }

        return this;
    }

    Command(key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string): ICommandBarService {
        return this.commandFunction(this._items, key, name, iconName, onClick, disabled, hidden, selected, after)        
    }

    CommandByDescription(commandDescription: ICommandDescription, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string): ICommandBarService {
        return this.commandFunction(this._items, commandDescription.key, commandDescription.name, commandDescription.iconName ? commandDescription.iconName : '', onClick, disabled, hidden, selected, after)        
    }

    FarCommand(key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean, hidden?: boolean, selected?: boolean, after?: string): ICommandBarService {
        return this.commandFunction(this._farItems, key, name, iconName, onClick, disabled, hidden, selected, after)        
    }

    SubCommand(key: string, name: string, iconName: string, onClick?: () => void, disabled?: boolean): ICommandBarService {

        // get the last item 
        const lastItem = this._items[this._items.length - 1]

        if (!lastItem.subMenuProps)
            lastItem.subMenuProps = { items: [] }

        
        lastItem.subMenuProps.styles = this._subItemHoveredStyle

        lastItem.subMenuProps.items.push(
            {
                key: key,
                text: name,
                cacheKey: key, 
                iconProps: { iconName: iconName },
                onClick: onClick,
                disabled: disabled,                
            }
        )
        
        return this;
    }    

    HasItems(): boolean {
        return this._items.length > 0 || this._farItems.length > 0
    }

    GetCommandBarItems(itemType: CommandBarItems = CommandBarItems.default): ICommandBarItemProps[] {
        if (itemType === CommandBarItems.farItems) {
            return this._farItems
        } if (itemType === CommandBarItems.farItemsIconOnly) {            
            return this._farItems.map((item) => {
                return {...item, iconOnly: true}
            })
        } else {
            return this._items
        }
    }  
    
    GetCommandDescriptions(): ICommandDescription[] {        
        return this.ToCommandDescriptions([...this._items, ...this._farItems])
    }

    private ToCommandDescriptions(itemProp: ICommandBarItemProps[]) : ICommandDescription[] {
        return itemProp.map((item) => this.ToCommandDescription(item))
    }

    private ToCommandDescription(itemProp: ICommandBarItemProps): ICommandDescription {
        return {
            key: itemProp.key,
            name: itemProp.text ? itemProp.text : itemProp.key,
            iconName: itemProp.iconProps ? itemProp.iconProps.iconName : undefined,                    
        }
    }
}

export function useCommandBarService() : ICommandBarService {

    const branding = useBranding()
    
    return new CommandBarService(branding.company);    
}