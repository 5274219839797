import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffect, useState } from "react"
import uuid from "react-uuid"
import { useNotifications } from "reapop"
import { ISdkReportDefinition } from "../../sdk/models/ISdkReportDefintion"
import { SdkProfileClient } from "../../sdk/SdkProfileClient"
import { SdkTenantReportClient } from "../../sdk/SdkTenantReportClient"
import { IColumnsBuilder, ISubCommandDefinition } from "../DetailsListHelper/ColumnsBuilder"
import { ICommandBarService } from "../DetailsListHelper/ComandBarService"
import { NavigationalDetailsListWithStandardOperations } from "../DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ReportAddOrEditDialog } from "./ReportAddOrEditDialog"
import { ReportInstancesDialog } from "./ReportInstancesDialog"
import { IConfirmDialogService } from "../../ds/components/ConfirmDialog"
import { ICommandPermission } from "../../commands/ICommandPermission"
import { ISdkProfile } from "../../sdk/models/ISdkProfile"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { ReportTransferOwnershipDialog } from "./ReportTransferOwnershipDialog"
import { useQuery } from "../../ds/components/RouterExtensions"
import { useSearchParams } from "react-router-dom"
import { useSubscriptionContext } from "../../authentication/AuthenticationSubscriptionContext"
import { ReportAddAiDialog } from "./ReportAddAiDialog"

export interface IReportManagementComponentProps {
    tenantId: string,
    reportCollectionType: "serviceProviderCollection" | undefined
}

export const ReportManagementComponent = (props: IReportManagementComponentProps) => {

    const auth = useAuthInContext(props.tenantId)            
    const subContext = useSubscriptionContext()        

    const { open } = useQuery()
    const [searchParams, setSearchParams] = useSearchParams();

    const { notify } = useNotifications()
    

    const [isReportAddOrEditDialogVisible, setIsReportAddOrEditDialogVisible] = useState(false)
    const [isReportAddAiDialogVisible, setIsReportAddAiDialogVisible] = useState(false)
    const [isReportInstancesDialogVisible, setIsReportInstancesDialogVisible] = useState(false)
    const [isReportTransferOwnerDialogVisible, setIsReportTransgerOwnerDialogVisible] = useState(false)
    const [isRefreshRequested, setRefreshRequested] = useState(false)
    const [itemToEdit, setItemToEdit] = useState<ISdkReportDefinition | undefined>(undefined)
    const [filterMyReports, setFilterMyReports] = useState<boolean>(true)
    const [selectedItems, setSelectedItems] = useState<ISdkReportDefinition[]>([])
    const [currentLoadedProfile, setCurrentLoadedProfile] = useState<ISdkProfile | undefined>(undefined)

    useEffect(() => {        
        const profileClient = new SdkProfileClient(auth.currentToken as string)
        profileClient.getProfile(props.tenantId).then((profile) => {            
            setCurrentLoadedProfile(profile)
        })
    // eslint-disable-next-line    
    }, [props.tenantId])

    const onClickColumn = (item: ISdkReportDefinition) => {
        setItemToEdit(item)
        setIsReportInstancesDialogVisible(true)
    }

    const onEditReport = (item: ISdkReportDefinition) => {
        setItemToEdit(item)        
        setIsReportAddOrEditDialogVisible(true)
    }

    const onRunReport = (item: ISdkReportDefinition) => {                    
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
        reportClient.triggerReportDefinitionRun(item.id).then(()=> {
            notify('Building a new report instance was started succssfully', 'success', { dismissible: true, dismissAfter: 2000 })                
        }).catch((error) => {
            notify('An error occured during the operation: ' + (error.message ? error.message : error), 'error', { dismissible: true })
        })                    
    }

    const setLockState = (item: ISdkReportDefinition, locked: boolean) => {
        // show the popup
        const notification = notify(locked ? 'Locking the report...' : 'Removing lock from report...', 'loading')                
                        
        // send the reequest 
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
        reportClient.updateReportDefinition(item.id, {...item, locked: locked}).then(()=> {            
            setRefreshRequested(!isRefreshRequested)
            notify({...notification, dismissAfter: 3000, status: 'success'})
        }).catch((error) => {
            notify({...notification, dismissible: true, status: 'error'})
        })        
    }

    const onLockReport = (item: ISdkReportDefinition) => {
        setLockState(item, true)       
    }

    const onUnlockReport = (item: ISdkReportDefinition) => {
        setLockState(item, false)       
    }

    const columnSubCommandsReportName: ISubCommandDefinition<ISdkReportDefinition>[] =  [      
        { iconName: 'faLock', description: 'Report is locked by the owner, unlock the report', onClick: (item) => { onUnlockReport(item)}, filter: (item) => item.locked, disabled: (item) => item.reportOwnerId !== currentLoadedProfile?.profileId},  
        { iconName: 'faLockOpen', description: 'Report is not locked, lock the report', onClick: (item) => { onLockReport(item)}, filter: (item) => !item.locked, disabled: (item) => item.reportOwnerId !== currentLoadedProfile?.profileId},  
        { iconName: 'faPlay', description: 'Run report to generate a new instance', onClick: (item) => { onRunReport(item)  }, filter: (item) => item.triggers && item.triggers.find(t => t.triggerType === 'Manual') ? true : false},
        { iconName: 'faPen', description: 'Edit the report definition', onClick: (item) => { onEditReport(item) }, disabled: (item) => item.locked && item.reportOwnerId !== currentLoadedProfile?.profileId}        
    ]

    const columnSubCommandsReportId: ISubCommandDefinition<ISdkReportDefinition>[] =  [
        { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ISdkReportDefinition) => {navigator.clipboard.writeText(item.id)}}        
    ]

    const onBuildColumns = (builder: IColumnsBuilder<ISdkReportDefinition>): void => {
        builder.TextColumn({name: "Name", value: "name", maxWidth: 450, onClick: onClickColumn, subCommands: columnSubCommandsReportName})
        builder.TextColumn({name: "Id", value: "id", maxWidth: 150, onClick: onClickColumn, subCommands: columnSubCommandsReportId })            
    }

    const onLoadData = async (): Promise<ISdkReportDefinition[]> => {
        
        setRefreshRequested(false)
        
        // load the profile in the correct context 
        const profileClient = new SdkProfileClient(auth.currentToken as string)
        const userProfile = await profileClient.getProfile(props.tenantId)
            
        // load the reports
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
        const allReports = await reportClient.getReportDefinitions()        
        const filteredReports = allReports.filter(x => x.editable === true).filter(x => filterMyReports ? x.reportOwnerId === userProfile.profileId : true)

        if (open) {
            const reportToOpen = filteredReports.find(x => x.id === open)
            if (reportToOpen) { onClickColumn(reportToOpen) }
            searchParams.delete('open')
            setSearchParams(searchParams)            
        }
        return filteredReports
    }

    const onNewReportDefinition = () => {
        setIsReportAddOrEditDialogVisible(true)
    }

    const onDeleteReportDefinitions = async (items: ISdkReportDefinition[]) => {
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)

        for (const item of items) {
            if (item.locked && item.reportOwnerId !== currentLoadedProfile?.profileId) {
                continue
            }

            await reportClient.deleteReportDefinition(item.id)            
        }
    }

    const onCloneReportDefinitions = async (items: ISdkReportDefinition[]) => {

        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)

        for (const item of items) {
            await reportClient.cloneReportDefinition(item.id)
        }        

        setRefreshRequested(true)
    }

    const onTransferingReports = async (items: ISdkReportDefinition[], toOwner: string) => {     
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)

        for (const item of items) {
            await reportClient.transferReportDefinition(item.id, toOwner)
        }        

        setRefreshRequested(true)
    }

    const onAddCommands = (commandBarService: ICommandBarService, _commandPermissions: ICommandPermission[], confirmDialogService: IConfirmDialogService) => {      
        
        const aiFeatureActive = subContext.subscriptions.find(s => s.id.indexOf('subscription.cloudbilling.ai') !== -1) !== undefined;
        commandBarService.Command('addAi', 'New AI Report', 'faBrainCircuit', () => { setIsReportAddAiDialogVisible(true)}, !aiFeatureActive, false, false, '_first')

        commandBarService.Command('clone', 'Clone', 'faClone', () => { 
            confirmDialogService.ask('Cloning Report Definition?', 'Do you want to clone all selected report definitions?', () => onCloneReportDefinitions(selectedItems))
        }, selectedItems.length === 0)

        commandBarService.Command('transfer', 'Transfer', 'faHandHoldingHand', () => { setIsReportTransgerOwnerDialogVisible(true) }, selectedItems.length === 0 || selectedItems.find(x => x.reportOwnerId !== currentLoadedProfile?.profileId) !== undefined)
            
        commandBarService.FarCommand('my', 'My Reports', 'faUser', () => { setFilterMyReports(true); setRefreshRequested(true) }, false, false, filterMyReports)
        commandBarService.FarCommand('all', 'All Reports', 'faGlobe', () => { setFilterMyReports(false);  setRefreshRequested(true) }, false, false, !filterMyReports)                
    }

    const onDismissAiDialogWithAction = (action: string, reportId: string) => {
        
        // load the reports
        const reportClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)
        reportClient.getReportDefinitions().then((reports) => {

            // close the dialog
            setIsReportAddAiDialogVisible(false);

            // find the report 
            const report = reports.find(x => x.id === reportId)

            if (report) { 

                switch(action) {
                    case 'openReport': 
                        onClickColumn(report)
                        break;
                    case 'editReport': 
                        onEditReport(report)
                        break;
                }            
            }
        })        
    }
    
    if (currentLoadedProfile === undefined) {
        return (<Spinner size={SpinnerSize.large} label="Loading reports..." labelPosition="bottom" style={{height: '100%'}} />)
    } else {
        return (    
            <>
                <NavigationalDetailsListWithStandardOperations 
                    uniqueIdentifier={'reportsroot' + uuid()}            
                    onBuildColumns={onBuildColumns}                                
                    onLoadData={(onLoadData)}
                    onNewItem={onNewReportDefinition}  
                    onDeleteItems={onDeleteReportDefinitions}                              
                    onAddCommands={onAddCommands}    
                    onSelectedItemsChanged={(items) => { setSelectedItems(items) }}
                    hideReferesh={false} 
                    preventInitialLoad={false}                        
                    allowSorting={true} 
                    defaultSortBy={'Name'}                    
                    disabledItemSelect={false}
                    allowSearching={false}
                    refresh={isRefreshRequested} />

                <ReportAddOrEditDialog tenantId={props.tenantId} reportCollectionType={props.reportCollectionType} isVisible={isReportAddOrEditDialogVisible} item={itemToEdit} dismissDialog={() => { setIsReportAddOrEditDialogVisible(false); setItemToEdit(undefined); setRefreshRequested(true)}} />   
                <ReportAddAiDialog tenantId={props.tenantId} reportCollectionType={props.reportCollectionType} isVisible={isReportAddAiDialogVisible} dismissDialog={() => { setIsReportAddAiDialogVisible(false); }} dismissDialogEx={onDismissAiDialogWithAction}/>    
                <ReportInstancesDialog tenantId={props.tenantId} reportCollectionType={props.reportCollectionType} isVisible={isReportInstancesDialogVisible} item={itemToEdit as ISdkReportDefinition} dismissDialog={() => { setIsReportInstancesDialogVisible(false); setItemToEdit(undefined); }} />            
                <ReportTransferOwnershipDialog tenantId={props.tenantId} isVisible={isReportTransferOwnerDialogVisible} dismissDialog={() => { setIsReportTransgerOwnerDialogVisible(false) } } onTransferToOwner={(newOwner: string) => { return onTransferingReports(selectedItems, newOwner) }} />
            </>
        )
    }
}

