import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";
import * as signalR from "@microsoft/signalr";
import { SdkTokenExchangeClient } from "./SdkTokenExchangeClient";
import { ISdkAiResponseMessage } from "./models/ISdkAiResponseMessage";

export class SdkTenantReportAiClient {

    private _sdkExchangeClient: SdkTokenExchangeClient

    constructor(token: string) {
        this._sdkExchangeClient = new SdkTokenExchangeClient(token);
    }

    public async AskAi(aiServiceId: string, tenantId: string, message: string, onUpdate: (responseMessage: ISdkAiResponseMessage) => void): Promise<void> {

        // discover the AI Service URI 
        const aiServiceUri = SdkClientServiceDiscoveryService.DiscoverAiServiceUri('/hubs/v1/' + aiServiceId) + '?tenantId=' + tenantId 
        
        // create the conection to the AI Service hub
        const connection = new signalR.HubConnectionBuilder().withUrl(aiServiceUri, { accessTokenFactory: async () => {

            // exchange the token for the AI Service
            const aiToken = await this._sdkExchangeClient.ExchangeTokenForService('mevodo.cloud.service.ai')

            // return the access token
            return aiToken.accessToken 
            
        }}).build();

        // handle the response messages        
        connection.on("UpdateFromAssistent", (message) => {
            
            if (onUpdate) {

                // parse the message
                const responseMessage = JSON.parse(message) as ISdkAiResponseMessage

                // invoke the update handler
                onUpdate(responseMessage)
            }
        })
        
                
        // start the connection
        await connection.start();

        // invoke the AI service by sending the prompt
        return await connection.invoke("NewPrompt", message)        
    }
}