import { IButtonProps, IconButton } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { useBranding } from "../../ds/components/BrandingProvider";

const useHeaderButtonStyles = makeStyles({
    headerButton: {
        marginTop: '8px',
        color: 'white',
        ":hover": {
            backgroundColor: 'transparent',
            color: 'white'
        }
    }
});

export const HeaderButton = (props: IButtonProps) => {    
    const componentStyles = useHeaderButtonStyles();    
    const branding = useBranding()    
    return (<IconButton {...props} className={componentStyles.headerButton} style={{color: branding.company?.colors.primaryFont}}/>)    
}