import { IDropdownOption, mergeStyleSets, PanelType, Spinner, SpinnerSize } from "@fluentui/react"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ICustomerManagementItem } from "./ServiceProviderCustomerManagementUIService"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient"
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox"

export interface ISyncDataintegrationCustomersInGroupPanelProps extends IAppWindowBaseProps { 
    tenantId: string,
    groupItem?: ICustomerManagementItem         
}

export const SyncDataintegrationCustomersInGroupPanel = (props: ISyncDataintegrationCustomersInGroupPanelProps) => {

    const auth = useAuthInContext(props.tenantId as string)
    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)
    const [availableDataintegrations, setAvailableDataintegrations] = useState<IDropdownOption[]>([])
    const [availableTimeframes, ] = useState<IDropdownOption[]>([
        { key: 'ytd', text: 'year to date' },
        { key: 'ltm', text: 'last 12 months' }        
    ])


    type SyncRequest = {
        dataintegrationId: string,
        timeframe: string,
        cleanUpData: boolean
    }

    const { handleSubmit, control, reset, formState } = useForm<SyncRequest>({
        defaultValues: {
            dataintegrationId: '',
            timeframe: 'ytd',
            cleanUpData: false
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const onDismissDialog = () => {            
        reset()
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<void> => {    
        
        const submitHandler = handleSubmit((data: SyncRequest) => {                                
            const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string);
            return sdkClient.syncDataIntegrationForCustomersInGroup(data.dataintegrationId, data.timeframe, data.cleanUpData, props.groupItem?.id as string)                                        
        })

        return submitHandler()        
    }

    useEffectAsync(async () => {

        if (!props.groupItem)
            return

        setIsPreparing(true)

        const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId, auth.currentToken as string);                 
        const dataintegrations = await sdkClient.listDataIntegration()
        setAvailableDataintegrations(dataintegrations.map(d => ({ key: d.id, text: d.name })).sort((a, b) => a.text.localeCompare(b.text)))
        
        setIsPreparing(false)

    }, [props.groupItem])
    

    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Synchronize all customers in group "' + props.groupItem?.name + '"'}
            onDismiss={onDismissDialog}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Trigger operation....'} 
            submitLabel={'Start Sychronization'} 
            dissmissLabel={'Cancel'}            
            isValid={formState.isValid}>
                
                { isPreparing ?
                    (<Spinner size={SpinnerSize.large} label="Preparing data..." labelPosition="bottom" style={{height: '100%'}} />) :
                    (<>
                        <p>
                            Selecte the data integration the synchronization should be started for. Only customers 
                            are in the selected group and associated with the selected data integration will be 
                            synchronized.
                        </p>    

                        <ControlledDropdown key={'dataintegrationId'} control={control} name={'dataintegrationId'} label={'Dataintegration'} rules={{ required: 'a valid value is required'}} options={availableDataintegrations} />            
                        <ControlledDropdown key={'timeframe'} control={control} name={'timeframe'} label={'Timeframe to sync'} rules={{ required: 'a valid value is required'}} options={availableTimeframes} />    

                        <p><strong>Advanced options:</strong></p>
                        <ControlledCheckbox key={'cleanUpData'} control={control} name={'cleanUpData'} label={'Removing existing data of the selected period during the data-synchronization'} className={classNames.checkbox} />            
        
                    </>
                    )
                }
        </PanelWithDefaultFooter>)
}