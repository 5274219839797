import { ICommandDescription } from "../lib/commands/ICommandDescription";
import { ICommandPermission } from "../lib/commands/ICommandPermission";

const verifyCanExecuteByKey = (key: string, permissions: ICommandPermission[]) => permissions.some(p => p.command.key === syncDataIntegrationCommandDescription.key && p.canExecute)

export interface IVerifyableCommandDescription extends ICommandDescription { 
    verifyCanExecute(permissions: ICommandPermission[]): boolean
}

export const manageAccess: IVerifyableCommandDescription = { key: 'managedAccess', name: 'Manage Access', iconName: 'faLockA', verifyCanExecute: (permissions) => verifyCanExecuteByKey(manageAccess.key, permissions) }
export const syncDataIntegrationCommandDescription: IVerifyableCommandDescription = { key: 'sync', name: 'Synchronize', iconName: 'faCloudScale', verifyCanExecute: (permissions) => verifyCanExecuteByKey(syncDataIntegrationCommandDescription.key, permissions) }
export const reInitializeDataIntegrationCommandDescription: IVerifyableCommandDescription = { key: 'reinit', name: 'Re-Initialize Integration', iconName: 'faCloudScale', verifyCanExecute: (permissions) => verifyCanExecuteByKey(reInitializeDataIntegrationCommandDescription.key, permissions) }
