import { PanelType } from "@fluentui/react"
import { IAppWindowBaseProps } from "../ds/components/AppWindow"
import { ChangelogUpdateManager } from "@changeloghq/changeloghq-sdk-browser"
import { PanelWithDefaultFooter } from "./DetailsListHelper/PanelWithDefaultFooter"

export interface IChangelogPanelProps extends IAppWindowBaseProps {
    url: string
}

export const ChangelogPanel = (props: IChangelogPanelProps) => {
        
    const dissmissDialog = () => {

        ChangelogUpdateManager.acknowledgeUpdates()

        if (props.dismissDialog) {
            props.dismissDialog()
        }
    }

    const title = 'Product Update Hub - See what\'s new!'
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}            
            headerText={title}
            type={PanelType.largeFixed}
            progessMessage=''
            onDismiss={dissmissDialog}
            submitLabel="Close"
            dissmissLabel="Close"
            noDissmissButton={true}
            isValid={true}
            onSubmit={() => { dissmissDialog(); return Promise.resolve() }}>
                 <iframe key={'changeloghq-root'} title={title} src={props.url + '?v=' + Date.now} style={{width: '100%', height: '100%', border: 'none'}}></iframe>
        </PanelWithDefaultFooter>
    )
}