import { useBoolean } from "@fluentui/react-hooks"
import { useAuth } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { IColumnsBuilder, ISubCommandDefinition } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ICommandBarService } from "../lib/components/DetailsListHelper/ComandBarService"
import { ISdkDataIntegration } from "../lib/sdk/models/ISdkDataIntegration"
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient"
import { ManagedLinkedCustomerDataSourcesDialog } from "./ManagedLinkedCustomerDataSourcesDialog"
import { ManageResourceAccess } from "./ManageResourceAccess"
import { ICommandDescription } from "../lib/commands/ICommandDescription"
import { ICommandPermission } from "../lib/commands/ICommandPermission"
import { useCommandPermissionEnforcer } from "../lib/commands/ComandPermissionEnforcer"
import { ConfirmDialog, useConfirmDialog } from "../lib/ds/components/ConfirmDialog"
import { AddOrEditCustomerDataIntegration } from "./AddOrEditCustomerDataIntegration"
import { SyncDataintegrationPeriodSelectorPanel } from "./SyncDataintegrationPeriodSelectorPanel"
import { ActivityLogPanel } from "../lib/components/ActivityLog/ActivityLogPanel"
import { manageAccess, reInitializeDataIntegrationCommandDescription, syncDataIntegrationCommandDescription } from "./PredefinedCommandDescriptions"

export interface IServiceProviderDataIntegrationsProps {}

export const ServiceProviderDataIntegrations = (props: IServiceProviderDataIntegrationsProps) => {

    // get the authenticaiton 
    const auth = useAuth()
    
    // get the current tenant state
    const { tenantId } = useParams()          

    // callback to remove the selected items    
    const [confirmDialog, confirmDialogService] = useConfirmDialog()
    
    // all the state     
    const [isManageAccessOpen, { setTrue: showManageAccess, setFalse: hideManageAccess}] = useBoolean(false)
    const [isAddOrEditIntegrationOpen, { setTrue: showAddOrEditIntegration, setFalse: hideAddOrEditIntegration}] = useBoolean(false)    
    const [isManageLinkedCustomerDatasourcesOpen, { setTrue: showManageLinkedCustomerDatasources, setFalse: hideManageLinkedCustomerDatasources}] = useBoolean(false)    
    const [refresh, { toggle: toggleRefresh} ] = useBoolean(false)
    const [itemtoEdit, setItemToEdit] = useState<ISdkDataIntegration>()    
    const [multipleItemsSelected, { setTrue: enableMultipleItemsSelected, setFalse: disableMultipleItemsSelected}] = useBoolean(false)    
    const [selectedItems, setSelectedItems] = useState<ISdkDataIntegration[]>([])
    const [isPeriodSelectorOpen, { setTrue: showPeriodSelector, setFalse: hidePeriodSelector}] = useBoolean(false)
    const [isActivityLogOpen, { setTrue: showActivityLog, setFalse: hideActivityLog}] = useBoolean(false)    

    // build the columns 
    const onColumnCellClick = (item: ISdkDataIntegration) => {
        setItemToEdit(item)
        showAddOrEditIntegration()
    }

    const onShowSynchronize = () => {
        if (selectedItems.length === 0) { return }
        setItemToEdit(selectedItems[0])
        showPeriodSelector()
    }

    const columnSubCommandsId: ISubCommandDefinition<ISdkDataIntegration>[] =  [
        { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ISdkDataIntegration) => {navigator.clipboard.writeText(item.id)}}        
    ]

    const columnSubCommandsName: ISubCommandDefinition<ISdkDataIntegration>[] =  [
        { iconName: 'faLink', description: 'Allows to manage linked customers', onClick: (item: ISdkDataIntegration) => {
            setItemToEdit(item)
            showManageLinkedCustomerDatasources()
        }},        
        { iconName: 'faListTimeline', description: 'Show activity log', elipsis: true, onClick: (item) => { setItemToEdit(item); showActivityLog()}}        
    ]
    
    const onBuildColumns = (builder: IColumnsBuilder<ISdkDataIntegration>) => {
        builder.IconColumn({name: "Status", headerIcon: 'faBuildings', iconName: (item) => 'fac' + item.type, onClick: onColumnCellClick})                        
        builder.TextColumn({name: "Name", value: "name", maxWidth: 350, onClick: onColumnCellClick, subCommands: columnSubCommandsName})                  
        builder.TextColumn({name: "Id", value: "id", maxWidth: 250, onClick: onColumnCellClick, subCommands: columnSubCommandsId})                              
        builder.TextColumn({name: "Description", value: "comment", maxWidth: 350, onClick: onColumnCellClick})                              
        builder.TextColumn({name: "Type", value: "type", maxWidth: 350, onClick: onColumnCellClick})                              
    }

    // handle data load
    const onLoadData = (): Promise<ISdkDataIntegration[]> => {         
         const sdkClient = new SdkCustomerDataIntegrationClient(tenantId as string, auth.currentToken as string);                 
         return sdkClient.listDataIntegration()        
    }
    const onNewItem = () => {
        showAddOrEditIntegration();
    }

    const onDeleteItems = (items: ISdkDataIntegration[]): Promise<void> => {
        // delete all data integrations
        const sdkClient = new SdkCustomerDataIntegrationClient(tenantId as string, auth.currentToken as string);                 
        return Promise.all(items.map((item) => sdkClient.deleteDataIntegration(item.id))).then(() => {
            return Promise.resolve()
        })
    }

    const onAddCommands = (commandBarService: ICommandBarService, commandPermission: ICommandPermission[]) => {
        commandBarService.CommandByDescription(manageAccess, showManageAccess, !manageAccess.verifyCanExecute(commandPermission))
        commandBarService.CommandByDescription(syncDataIntegrationCommandDescription, onShowSynchronize, !multipleItemsSelected || !syncDataIntegrationCommandDescription.verifyCanExecute(commandPermission))
        commandBarService.CommandByDescription(reInitializeDataIntegrationCommandDescription, handleReInitialize, !multipleItemsSelected || !reInitializeDataIntegrationCommandDescription.verifyCanExecute(commandPermission))
    } 
          
    const permissionEnforcer = useCommandPermissionEnforcer(tenantId as string, 'integration', 'svpcustomerdataintegration')
    const onEvaluateCommandPermissions = async (commands: ICommandDescription[]): Promise<ICommandPermission[]> => {
        return await permissionEnforcer.enforce(commands)        
    }

    const onSelectedItemsChanged = (selectedItems: ISdkDataIntegration[], allItems: ISdkDataIntegration[]): void => {
        if (selectedItems.length > 0) {
            enableMultipleItemsSelected()
        } else {         
            disableMultipleItemsSelected()
        }

        setSelectedItems(selectedItems)
    }

    // build the commandbar    
    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()  
            setItemToEdit(undefined)
            toggleRefresh()                      
        }
    }

    const handleReInitialize = () => {

        confirmDialogService.ask(
            'Do you really want to re-initialize ' + selectedItems.length + ' data integtation' + (selectedItems.length > 1 ? 's' : ''), 
            'Do you really want to re-initialize the selected data integrations? The system will re-import and re-calculate all data including historical data. This operation can take a while.',
            () => {
                
                const sdkClient = new SdkCustomerDataIntegrationClient(tenantId as string, auth.currentToken as string);
                const promisses = selectedItems.map((item) => sdkClient.reInitializeDataIntegration(item.id))
                    
                return Promise.all(promisses).then(() => {
                    toggleRefresh()           
                    return Promise.resolve()         
                })                
            })            
    }    
        
    

    return (
        <div className="mv-content-container">
            <PageHeader title={'Data Integrations'}>            
                <span>
                    Data integrations allows to connect cloud provider portals and other billing services into the solution. Every data integration 
                    allows the manage customers fully automatically or offers a guided process to associate data with existing customers. The main purpose 
                    of a data integration is to generate data sources for the customers which will be processed from the system on a regular basis.
                    </span>
            </PageHeader>

            <NavigationalDetailsListWithStandardOperations 
                uniqueIdentifier={'integrationsroot'}   
                onEvaluateCommandPermissions={onEvaluateCommandPermissions}             
                onBuildColumns={onBuildColumns}
                onNewItem={onNewItem}
                newItemLabel={'Register Integration'}                
                onLoadData={onLoadData}
                hideReferesh={false} 
                refresh={refresh}
                onDeleteItems={onDeleteItems}                
                onAddCommands={onAddCommands}
                onSelectedItemsChanged={onSelectedItemsChanged}
                allowSorting={true} />

            <ManageResourceAccess isVisible={isManageAccessOpen} dismissDialog={handleDissmissWithReload(hideManageAccess)} tenantId={tenantId as string} resourceName={'Data Integrations'} resourceType={'integration'} />            
            <ManagedLinkedCustomerDataSourcesDialog isVisible={isManageLinkedCustomerDatasourcesOpen} dismissDialog={hideManageLinkedCustomerDatasources} tenantId={tenantId as string} itemId={itemtoEdit ? itemtoEdit.id : ''} />
            <SyncDataintegrationPeriodSelectorPanel tenantId={tenantId as string} isVisible={isPeriodSelectorOpen} dismissDialog={handleDissmissWithReload(hidePeriodSelector)} dataIntegration={itemtoEdit ? itemtoEdit : undefined} />
            <AddOrEditCustomerDataIntegration isVisible={isAddOrEditIntegrationOpen} dismissDialog={handleDissmissWithReload(hideAddOrEditIntegration)} tenantId={tenantId as string} itemId={itemtoEdit ? itemtoEdit.id : undefined} parent={''} />        
            <ActivityLogPanel isVisible={isActivityLogOpen} dismissDialog={hideActivityLog} tenantId={tenantId as string} objectId={itemtoEdit ? itemtoEdit.id : ''} objectType={'subject'} objectDisplayName={itemtoEdit ? itemtoEdit.name : ''} />
            <ConfirmDialog {...confirmDialog}/>                 
        </div>
    )
}