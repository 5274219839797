import { Icon } from "@fluentui/react";
import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'inline-flex'
    },

    button: {        
        height: '24px',        
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        backgroundColor: 'initial',
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('20px')
    },

    buttonClickable: {        
        height: '24px',        
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        backgroundColor: 'initial',
        color: tokens.colorNeutralForeground1,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('20px'),
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            color: 'white'
        }
    },

    iconWrapper: {
        paddingTop: '2px',
        paddingRight: '4px'    
    },

    icon: {
        '> svg': {
            height: '12px'
        }        
    },

    titleWrapper: {
        fontSize: '12px',
        fontWeight: '400',   
        marginTop: '3px',
        color: "inherit"        
    },

    crossWrapper: {
        paddingTop: '3px',
        paddingLeft: '6px',        
        color: "inherit"
    }
});

export interface IPillControlProps {
    id: string    
    children?: React.ReactNode
    iconName?: string
    onXMarkClick?: () => void
    onClick?: () => void
    gap?: string
}

export const PillControl = (props: IPillControlProps) => {
    const styles = useStyles();
    
    const gapStyle = props.gap ? { marginRight: props.gap } : {}

    return (
        <div className={styles.root} key={props.id} style={gapStyle}>
            <div>
                <button className={props.onClick ? styles.buttonClickable : styles.button} onClick={props.onClick} >
                    { props.iconName ? (<div className={styles.iconWrapper}><Icon iconName={props.iconName} className={styles.icon} /></div>) : (<></>) }
                    <span className={styles.titleWrapper}>
                        <div>
                            {props.children}
                        </div>
                    </span>
                    { props.onXMarkClick ? (
                        <div className={styles.crossWrapper}>                        
                            <span>
                                <FontAwesomeIcon icon={thin('xmark')} onClick={props.onXMarkClick}/>
                            </span>                        
                        </div>
                    ) : (<></>) }
                </button>
            </div>
        </div>
    )
}