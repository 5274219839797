import { ISdkTokenExchangeResult } from "./models/ISdkTokenExchangeResult";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";


export class SdkTokenExchangeClient {

    private _sdkClient: SdkClient<ISdkTokenExchangeResult>;

    constructor(token: string) {
        this._sdkClient = SdkClientServiceDiscoveryService.DiscoverAccounts<ISdkTokenExchangeResult>('/api/data/v1/profiles/my', token);        
    }

    public async ExchangeTokenForService(targetServiceId: string): Promise<ISdkTokenExchangeResult> {
        return await this._sdkClient.get('/token/' + targetServiceId);
    }    
}