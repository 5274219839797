import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChatControl.scss";
import { Button } from "@fluentui/react-components";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { PillControl } from "../../components-v9/PillControl";
import { ProgressIndicator } from "@fluentui/react";

type ChatMessage = {
    message: string
    actions?: { label: string, icon: string, onClick: () => void }[]
    isUser: boolean    
}

export type ChatResponseOperation = {
    message: string,
    actions: { label: string, icon: string, onClick: () => void }[]
}

export interface IChatComponentProps {    
    height: string
    onUserRequest?: (prompt: string, responseHandler: (response: ChatResponseOperation ) => void) => Promise<void>    
}

export const ChatComponent = (props: IChatComponentProps) => {
    
    const maxPromptLength = 10000
    const [curentPrompt, setCurrentPrompt] = useState<string | null>(null)    
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        setChatMessages([{ message: 'Hi there! Explain the problem you want to solve with a report or the question you want to answer. I will try to generate the solution as a report for you!', isUser: false}])
    }, [])

    const handlePromptInput = () => {
        
        // get the current prompt messgae 
        const currentPromptText = curentPrompt as string

        // start the processing
        setIsProcessing(true)

        // reset the input bix 
        setCurrentPrompt(null)

        // transfer the prompt into the chat box on the right side
        setChatMessages(prev => [...prev, { message: currentPromptText as string, isUser: true}])
    
        // give it to the parent component        
        if (props.onUserRequest) { 
            
            // ask the AI for responses
            props.onUserRequest(currentPromptText as string, (assistentResponse: ChatResponseOperation) => {

                // transfer every response from the assistant into the chat box on the left side
                setChatMessages(prev => [...prev, { message: assistentResponse.message, actions: assistentResponse.actions, isUser: false}])
            }).then(() => {
                setIsProcessing(false)
            })
        } else {            
            setIsProcessing(false)
        }
    }

    const renderChatMessageUser = (message: ChatMessage, prevMessage: ChatMessage) => {
        return <div className="chat-message-user">
            <div>{message.message}</div>
        </div>
    }

    const renderChatMessageAssistant = (message: ChatMessage, prevMessage: ChatMessage) => {
        return <div className="chat-message-assistant">
            {(!prevMessage || prevMessage.isUser) && <div className="assistant-header"><FontAwesomeIcon icon={regular('robot')} />&nbsp;Report Assistant</div>}
            <div className="assistant-body">{message.message}</div>            
            { message.actions && message.actions.length > 0 && <div className="assistant-actions">
                { message.actions.map((action, index) => <PillControl id={action.label} iconName={action.icon} onClick={action.onClick} gap='5px'>{action.label}</PillControl>)}                
            </div>}            
        </div>
    }

    return (
        <AutoSizer disableWidth>
            {({ height }) => (
                <div className="chat-control" style={{height: height}}>            
                    <div className="chat-box">                        
                        { chatMessages.map((message, index) => message.isUser ? renderChatMessageUser(message, chatMessages[index -1]) : renderChatMessageAssistant(message, chatMessages[index -1])) }                        
                    </div>
                    {isProcessing && <ProgressIndicator />}
                    <div className="prompt-box">
                        <p className="prompt-input" contentEditable={true} suppressContentEditableWarning={true} onBlur={(e) => e.currentTarget.innerText.length > maxPromptLength ? () => {} : setCurrentPrompt(e.currentTarget.innerText)}>{curentPrompt}</p>
                        <div className="prompt-actions">
                            <p>{curentPrompt ? curentPrompt.length : '0'}/{maxPromptLength}</p>
                            <Button icon={<FontAwesomeIcon icon={solid('paper-plane-top')} />} onClick={handlePromptInput} disabled={isProcessing} />                    
                        </div>                                                                    
                    </div>
                </div>        
            )}
        </AutoSizer>
    )
}